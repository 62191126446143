import React, {createContext, useContext, useState, ReactNode} from 'react';

export enum AlertType {
    info,
    warning,
    error,
}

type AlertContextType = {
    showAlert: (message: string, type: AlertType) => void;
};

const AlertContext = createContext<AlertContextType | undefined>(undefined);

const AlertProvider: React.FC<{ children: ReactNode }> = ({children}) => {
    const [alert, setAlert] = useState<{ message: string, type: AlertType } | null>(null);

    const showAlert = (message: string, type: AlertType) => {
        console.log(`Show Alert. Type: ${type}. Message: ${message}`);
        setAlert({message, type});

        setTimeout(() => {
            setAlert(null);
        }, 2900);
    };

    return (
        <AlertContext.Provider value={{showAlert}}>
            {children}
            {alert && <BestAlert text={alert.message} type={alert.type}/>}
        </AlertContext.Provider>
    );
};

const useAlert = () => {
    const context = useContext(AlertContext);
    if (context === undefined) {
        throw new Error('useAlert must be used within an AlertProvider');
    }
    return context;
};

const BestAlert: React.FC<{ text: string, type: AlertType }> = ({text, type}) => {
    const getAlertClass = () => {
        switch (type) {
            case AlertType.info:
                return 'info';
            case AlertType.warning:
                return 'warning';
            case AlertType.error:
                return 'error';
            default:
                return '';
        }
    };

    return (
        <article className={`best-alert ${getAlertClass()}`}>
            <h1>{text}</h1>
        </article>
    );
};

export {AlertProvider, useAlert};
