import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import BarLoader from 'react-spinners/BarLoader'
import {AlertType, useAlert} from 'tools/alert-context';
import {supabase} from "../../db/supabase";
import {loaderContainerStyle, override, spanStyle} from "../../tools/constants";

interface PostsType {
    id: string;
    title: string;
    shortinfo: string;
}

function BlogView() {
    const [posts, setPosts] = useState<PostsType | any>(null)

    const {showAlert} = useAlert()

    useEffect(() => {
        async function fetchPosts() {
            try {
                const {data, error} = await supabase
                    .from('posts')
                    .select('id, title, shortinfo')
                    .order('date', {ascending: false});

                if (error) {
                    setTimeout(() => {
                        window.location.href = '/'
                    }, 3000)

                    showAlert('Ошибка загрузки постов', AlertType.error)
                } else if (data) {
                    setPosts(data)
                }
            } catch (error: any) {
                showAlert('Ошибка при подключении к базе данных', AlertType.error)
            }
        }

        fetchPosts().then(() => console.log('Load posts....'))
    }, [])

    if (!posts) {
        return (
            <div style={loaderContainerStyle}>
                <span style={spanStyle}>Загрузка...</span>
                <BarLoader color='#032CB5' cssOverride={override}/>
            </div>
        )
    }

    return (
        <main>
            <section className="urls_first_section">
                <article>
                    <Link to="/">Главная ▶ </Link>
                    <Link to="/blog">Блог</Link>

                    <h2>Блог</h2>
                </article>
            </section>

            <section className="blog_text">
                <article>
                    <span>Наш блог. Посты:</span>
                    <h2>Наш блог. Посты:</h2>
                </article>
            </section>

            <section className="blog_section">
                {posts.map((post: PostsType) => (
                    <div key={post.id}>
                        <h2>{post.title}</h2>
                        <p>{post.shortinfo}</p>
                        <Link to={`/blog/post/${post.id}`}>
                            <button>Подробнее</button>
                        </Link>
                    </div>
                ))}
            </section>
        </main>
    )
}

export default React.memo(BlogView)
