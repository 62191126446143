import {CSSProperties} from "react";

export const not_secret_token = '7ym89chc9cw*^^&*NT^R&^(&)<e8c8cgjcgrjg*^%%&)(^recgrcr@&^@^&@@cpkcrskc[.[k90gtvyh8bvg'
export const server = 'https://server.qwy-games.ru'
export const cookie_express_in = 7

export interface PostType {
    id: string;
    title: string;
    date: string;
    content: string;
}

export interface PostEditType {
    id: string;
    title: string;
    date: string;
    content: string;
    tags: string;
}

export const loaderContainerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    textAlign: 'center',
    fontSize: '50px',
    fontFamily: 'monospace',
    color: '#032CB5'
}

export const spanStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 'auto',
    marginBottom: '-25vh'
}

export const override: CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
    width: '80%',
    borderRadius: '100px'
}

export type DefaultExportType<T> = {
    default: T
}
