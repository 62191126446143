import {useLocation} from 'react-router-dom'
import {useEffect} from 'react'

export default function Scroll() {
    const {pathname, hash} = useLocation()

    useEffect(() => {
        if (hash) {
            const element = document.getElementById(hash.substring(1))
            if (element) {
                element.scrollIntoView({behavior: 'smooth'})
            }
        } else {
            window.scrollTo(0, 0);
        }
    }, [pathname, hash])

    return null
}