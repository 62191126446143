import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './app'
import reportWebVitals from './reportWebVitals'
import {AlertProvider} from "./tools/alert-context";

(function() {
    const currentDomain = window.location.hostname;
    const newDomain = 'qwy-games.ru'

    if (currentDomain !== 'localhost') {
        if (currentDomain !== newDomain) {
            const currentPath = window.location.pathname + window.location.search;
            window.location.href = `https://${newDomain}${currentPath}`
        } else {
            console.log('Domain connected!')
        }
    }
})()

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <AlertProvider>
        <App/>
    </AlertProvider>
)

reportWebVitals()
