import './css/index.css'
import './css/media.css'
import './css/font.css'
import './css/app.css'
import './css/boxicons.min.css'
import BarLoader from 'react-spinners/BarLoader'
import React, {useEffect, useState, Suspense, lazy, useCallback} from 'react'
import {BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom'
import Cookies from 'js-cookie'
import axios from 'axios'
import {DefaultExportType, loaderContainerStyle, not_secret_token, override, server, spanStyle} from './tools/constants'
import Post from './pages/blog/post'
import Header from './components/header'
import Footer from './components/footer'
import Scroll from './components/scroll'
import BlogView from './pages/blog/blog-view'

const loadWithDelay = <T extends React.ComponentType<any>>(importFunc: () => Promise<DefaultExportType<T>>) =>
    new Promise<DefaultExportType<T>>((resolve) => {
        setTimeout(() => {
            importFunc().then(resolve)
        }, 200)
    })

const lazyWithDelay = <T extends React.ComponentType<any>>(importFunc: () => Promise<DefaultExportType<T>>) =>
    lazy(() => loadWithDelay(importFunc))

const Home = lazyWithDelay(() => import('./pages/home'))
const About = lazyWithDelay(() => import('./pages/about'))
const NotFoundPage = lazyWithDelay(() => import('./pages/errors/404'))
const Projects = lazyWithDelay(() => import('./pages/projects'))
const Contacts = lazyWithDelay(() => import('./pages/contacts'))
const TermsPrivacy = lazyWithDelay(() => import('./pages/terms-privacy'))
const FruitNinja = lazyWithDelay(() => import('./pages/projects/fruit-ninja'))
const DeathWar = lazyWithDelay(() => import('./pages/projects/death-war'))
const EscapeFromGrisha = lazyWithDelay(() => import('./pages/projects/escape-from-grisha'))
const AdventuresEric = lazyWithDelay(() => import('./pages/projects/adventures-eric'))
const SlimeCoin = lazyWithDelay(() => import('./pages/projects/slimecoin'))
const Register = lazyWithDelay(() => import('./pages/auth/register'))
const Login = lazyWithDelay(() => import('./pages/auth/login'))
const Admin = lazyWithDelay(() => import('./pages/admin/admin-panel'))
const Profile = lazyWithDelay(() => import('./pages/auth/profile'))
const LoginPanel = lazyWithDelay(() => import('./pages/admin/login-panel'))
const Create = lazyWithDelay(() => import('./pages/admin/posts/create'))
const Edit = lazyWithDelay(() => import('./pages/admin/posts/edit'))
const Delete = lazyWithDelay(() => import('./pages/admin/posts/delete'))
const Secret = lazyWithDelay(() => import('./pages/secret'))
const Verify = lazyWithDelay(() => import('./pages/auth/verify'))

export default function App() {
    const [isAuth, setIsAuth] = useState(false)
    const [isAdminAuth, setIsAdminAuth] = useState(false)
    const [theme, setTheme] = useState('light')
    const [loading, setLoading] = useState(true)

    const checkAuth = useCallback(async () => {
        const isAuthToken = Cookies.get('isAuth')

        if (isAuthToken) {
            try {
                const response = await axios.post(`${server}/api/v1/tools/verifyJwt`, {token: isAuthToken}, {
                    headers: {
                        'Authorization': `Bearer ${not_secret_token}`,
                        'Content-Type': 'application/json'
                    }
                })
                setIsAuth(response.data.valid)
            } catch (error) {
                console.error('Ошибка проверки токена', error)
                setIsAuth(false)
            }
        } else {
            setIsAuth(false)
        }
    }, [])

    const checkAdminAuth = useCallback(async () => {
        const isAuthToken = Cookies.get('isAdminAuth')

        if (isAuthToken) {
            try {
                const response = await axios.post(`${server}/api/v1/tools/verifyJwt`, {token: isAuthToken}, {
                    headers: {
                        'Authorization': `Bearer ${not_secret_token}`,
                        'Content-Type': 'application/json'
                    }
                })
                setIsAdminAuth(response.data.valid)
            } catch (error) {
                console.error('Ошибка проверки токена', error)
                setIsAdminAuth(false)
            }
        } else {
            setIsAdminAuth(false)
        }
    }, [])

    useEffect(() => {
        const savedTheme = localStorage.getItem('theme')

        if (savedTheme) {
            setTheme(savedTheme)
        } else {
            const userPrefersDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
            if (userPrefersDark) {
                setTheme('dark')
            }
        }
    }, [])

    useEffect(() => {
        document.documentElement.setAttribute('data-theme', theme)
    }, [theme])

    useEffect(() => {
        Promise.all([
            checkAuth(),
            checkAdminAuth()
        ]).then(() => {
            setTimeout(() => {
                setLoading(false)
            }, 500)
        })
    }, [])

    useEffect(() => {
        let lastScrollTop = 0
        const header = document.getElementById('default_header')

        const handleScroll = () => {
            const currentScroll = document.documentElement.scrollTop
            const screenWidth = window.innerWidth

            if (screenWidth > 870 && header) {
                if (currentScroll > 120 && currentScroll > lastScrollTop) {
                    header.classList.add('hidden')

                    setTimeout(() => {
                        if (header) {
                            header.style.display = 'none'
                        }
                    }, 300)
                } else {
                    if (header) {
                        header.style.display = 'flex'
                        header.classList.remove('hidden')
                    }
                }
            }

            lastScrollTop = currentScroll <= 0 ? 0 : currentScroll
        }

        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    if (loading) {
        return (
            <div style={loaderContainerStyle}>
                <span style={spanStyle}>Загрузка...</span>
                <BarLoader color='#032CB5' cssOverride={override}/>
            </div>
        )
    }

    return (
        <Router>
            <Scroll/>
            <Header/>

            <Suspense fallback={<div style={loaderContainerStyle}><span style={spanStyle}>Загрузка...</span>
                <BarLoader color='#032CB5' cssOverride={override}/></div>}>

                <Routes>
                    <Route path='/' element={<Home/>}/>
                    <Route path='/about' element={<About/>}/>
                    <Route path='/terms-privacy' element={<TermsPrivacy/>}/>
                    <Route path='/contacts' element={<Contacts/>}/>

                    <Route path='/blog' element={<BlogView/>}/>
                    <Route path='/blog/post' element={<Navigate to='/blog'/>}/>
                    <Route path='/blog/post/:postId' element={<Post/>}/>

                    <Route path='/projects' element={<Projects/>}/>
                    <Route path='/projects/*' element={<Navigate to='/projects'/>}/>
                    <Route path='/projects/fruit-ninja' element={<FruitNinja/>}/>
                    <Route path='/projects/adventures-eric' element={<AdventuresEric/>}/>
                    <Route path='/projects/death-war' element={<DeathWar/>}/>
                    <Route path='/projects/escape-from-grisha' element={<EscapeFromGrisha/>}/>
                    <Route path='/projects/slimecoin' element={<SlimeCoin/>}/>

                    {isAdminAuth ? (
                        <>
                            <Route path='/admin' element={<Admin/>}/>
                            <Route path='/admin/action/create' element={<Create/>}/>
                            <Route path='/admin/action/edit/:postId' element={<Edit/>}/>
                            <Route path='/admin/action/delete/:postId' element={<Delete/>}/>

                            <Route path='/admin/login' element={<Navigate to='/admin'/>}/>
                            <Route path='/admin/action' element={<Navigate to='/admin'/>}/>
                            <Route path='/admin/action/edit' element={<Navigate to='/admin'/>}/>
                            <Route path='/admin/action/delete' element={<Navigate to='/admin'/>}/>
                        </>
                    ) : (
                        <>
                            <Route path='/admin/login' element={<LoginPanel/>}/>

                            <Route path='/admin' element={<Navigate to='/admin/login'/>}/>
                            <Route path='/admin/action' element={<Navigate to='/admin/login'/>}/>
                            <Route path='/admin/action/create' element={<Navigate to='/admin/login'/>}/>
                            <Route path='/admin/action/edit' element={<Navigate to='/admin/login'/>}/>
                            <Route path='/admin/action/delete' element={<Navigate to='/admin/login'/>}/>
                            <Route path='/admin/action/delete/:postId' element={<Navigate to='/admin/login'/>}/>
                            <Route path='/admin/action/edit/:postId' element={<Navigate to='/admin/login'/>}/>
                        </>
                    )}

                    {isAuth ? (
                        <>
                            <Route path='/secret' element={<Secret/>}/>
                            <Route path='/auth' element={<Navigate to='/profile'/>}/>
                            <Route path='/profile' element={<Profile/>}/>
                            <Route path='/auth/register' element={<Navigate to='/profile'/>}/>
                            <Route path='/auth/login' element={<Navigate to='/profile'/>}/>
                            <Route path='/verify' element={<Verify/>}/>
                        </>

                    ) : (
                        <>
                            <Route path='/verify' element={<Navigate to='/auth/login'/>}/>
                            <Route path='/profile' element={<Navigate to='/auth'/>}/>
                            <Route path='/auth' element={<Navigate to='/auth/register'/>}/>
                            <Route path='/auth/register' element={<Register/>}/>
                            <Route path='/auth/login' element={<Login/>}/>
                        </>
                    )}

                    <Route path='*' element={<NotFoundPage/>}/>
                </Routes>
            </Suspense>

            <Footer/>
        </Router>
    )
}
