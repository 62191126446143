import React from 'react';
import heart from '../img/heart.png';
import {Link} from "react-router-dom";

export default function Footer() {
    return (
        <footer>
            <article>
                <div>
                    <h4>Продукт</h4>
                    <ul>
                        <li><Link to="#">Безопасность</Link></li>
                        <li><Link to="#">Цены</Link></li>
                        <li><Link to="/projects#games">Продукты</Link></li>
                    </ul>
                </div>

                <div>
                    <h4>Компания</h4>
                    <ul>
                        <li><Link to="/#about_us">О нас</Link></li>
                        <li><Link to="/blog#blog">Блог</Link></li>
                        <li><Link to="/about#team">Команда</Link></li>
                    </ul>
                </div>

                <div>
                    <h4>Юридическая информация</h4>
                    <ul>
                        <li><Link to="/terms-privacy#terms">Условия использования</Link></li>
                        <li><Link to="/terms-privacy#privacy">Политика конфиденциальности</Link></li>
                        <li><Link to="/terms-privacy#coockie">Информация о файлах cookie</Link></li>
                    </ul>
                </div>
            </article>

            <div className="footer_line">
                <span></span>
            </div>

            <article className="copy">
                <p>QWY_Games ©2022-2024. Все права защищены | Сайт сделан с <Link to="/secret#image"><img
                    src={heart} loading='lazy' alt="heart"/></Link>
                </p>

                <div>
                    <Link to="https://discord.gg/pYbYwHTrDd" aria-label='Discord' target="_blank"
                          rel="noopener noreferrer">
                        <i className='bx bxl-discord-alt'></i></Link>
                    <Link to="https://t.me/qwy_games" aria-label='Telegram' target="_blank" rel="noopener noreferrer">
                        <i className='bx bxl-telegram'></i></Link>
                    <Link to="https://vk.com/qwy_games" aria-label='Vk' target="_blank" rel="noopener noreferrer">
                        <i className='bx bxl-vk'></i></Link>
                    <Link to="https://tiktok.com/@qwy_games" aria-label='TikTok' target="_blank"
                          rel="noopener noreferrer">
                        <i className='bx bxl-tiktok'></i></Link>
                </div>
            </article>
        </footer>
    );
}
