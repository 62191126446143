import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import icon_129x129 from '../img/icon/android-chrome-192x192.webp'
import Cookies from "js-cookie";
import axios from 'axios'
import {not_secret_token, server} from "../tools/constants";

export default function Header() {
    const [isAuth, setIsAuth] = useState(false);

    useEffect(() => {
        async function checkAuth() {
            const isAuthToken = Cookies.get('isAuth');

            if (isAuthToken) {
                try {
                    const response = await axios.post(`${server}/api/v1/tools/verifyJwt`, {token: isAuthToken}, {
                        headers: {
                            'Authorization': `Bearer ${not_secret_token}`,
                            'Content-Type': 'application/json'
                        }
                    })
                    setIsAuth(response.data.valid);
                } catch (error) {
                    console.error('Ошибка проверки токена', error);
                    setIsAuth(false);
                }
            } else {
                setIsAuth(false)
            }
        }

        checkAuth().then(() => console.log('Verifying...'));
    }, []);

    return (
        <header>
            <div className='crutch'></div>

            <section className="default_header" id="default_header">
                <Link to="/">
                    <img src={icon_129x129} className="locked" alt="logo"/>
                </Link>

                <article>
                    <ul>
                        <li><Link to="/">Главная</Link></li>
                        <li><Link to="/about">О нас</Link></li>
                        <li><Link to="/projects">Проекты</Link></li>
                        <li><Link to="/blog">Блог</Link></li>
                        <li><Link to="/contacts">Контакты</Link></li>
                    </ul>
                </article>

                <article>
                    {isAuth ? (
                        <Link to="/profile">
                            <button>Профиль</button>
                        </Link>
                    ) : (
                        <Link to="/auth/register">
                            <button>Зарегистрироваться</button>
                        </Link>
                    )}
                </article>
            </section>

            <section className="mobile_header" id="mobile_header">
                <Link to="/">
                    <img src={icon_129x129} className="locked" alt="logo"/>
                </Link>

                <article>
                    <Link to="/">
                        <h1>QWY_Games</h1>
                    </Link>
                </article>

                <article>
                    {isAuth ? (
                        <Link to="/profile">
                            <button>Профиль</button>
                        </Link>
                    ) : (
                        <Link to="/auth/register">
                            <button>Зарегистрироваться</button>
                        </Link>
                    )}
                </article>
            </section>
        </header>
    )
}