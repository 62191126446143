import React, {CSSProperties, useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {supabase} from "../../db/supabase";
import BarLoader from 'react-spinners/BarLoader'
import {loaderContainerStyle, override, PostType, spanStyle} from "../../tools/constants";
import {AlertType, useAlert} from "../../tools/alert-context";

function Post() {
    const {postId} = useParams();
    const [post, setPost] = useState<PostType | any>(null)

    const {showAlert} = useAlert()

    const formatDate = (date: any) => {
        const options = {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            timeZone: 'Europe/Moscow',
        }
        return date.toLocaleDateString('ru-RU', options)
    }

    useEffect(() => {
        async function fetchPost() {
            try {
                const {data, error} = await supabase
                    .from('posts')
                    .select('*')
                    .eq('id', postId)
                    .single()

                if (error) {
                    setTimeout(() => {
                        window.location.href = '/blog'
                    }, 3000)

                    showAlert('Ошибка загрузки поста', AlertType.error)
                } else if (data) {
                    setPost(data)
                }
            } catch (error: any) {
                showAlert('Ошибка при подключении к базе данных', AlertType.error)
            }
        }

        fetchPost().then(() => console.log('Load admin posts....'))
    }, [postId])

    if (!post) {
        return (
            <div style={loaderContainerStyle}>
                <span style={spanStyle}>Загрузка...</span>
                <BarLoader color='#032CB5' cssOverride={override}/>
            </div>
        )
    }

    return (
        <main>
            <section className="urls_first_section">
                <article>
                    <Link to="/">Главная ▶ </Link>
                    <Link to="/blog">Блог ▶ </Link>
                    <Link to={`#`}>Пост "{post.title}"</Link>
                    <h2>Пост "{post.title}"</h2>
                </article>
            </section>
            <div className="post_wrap">
                <section className="post_section">
                    <article>
                        <h1>{post.title}</h1>
                        <h3>{formatDate(new Date(post.date))} (МСК)</h3>
                        <p>{post.content}</p>
                    </article>
                </section>
            </div>
        </main>
    )
}

export default React.memo(Post)
